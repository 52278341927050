import React from "react"
import {graphql} from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../layouts/default"
import SEO from "../components/seo"

const Contact = ({ data }) => {

  const { projects } = data.craft
  console.log(projects)

  return (

    <Layout>
      <SEO title="Branding, Print and Digital Agency based in Manchester &amp; Melbourne." />
      <Helmet bodyAttributes={{ class: "s-contact" }} />

        <section className="o-section o-section--no-pad-bottom">
          <div className="u-wrapper">
            <h2 className="u-heading u-heading--centered u-heading--reverse">Let's Talk</h2>
            <div className="c-contact u-columns u-columns--3">
              <div className="c-contact__location">
                <h3>New Business</h3>
                <a href="mailto:studio@warehousesix.com">studio@warehousesix.com</a>
              </div>
              <div className="c-contact__location">
                <h3>Manchester</h3>
                <p>Studio 328d, <br></br>
                  Tanner Business Centre, Greenfield,<br></br>
                  Gtr Manchester, OL3 7NH, <br></br>
                  United Kingdom</p>
                  <a href="mailto:alex@warehousesix.com">alex@warehousesix.com</a>
              </div>
              <div className="c-contact__location">
                <h3>Melbourne</h3>
                <p>188 Johnston Street, <br></br>
                Collingwood, 3066, <br></br>
                  VIC, <br></br>
                  Australia</p>
                  <a href="mailto:darren@warehousesix.com">darren@warehousesix.com</a>
              </div>
            </div>
          </div>
        </section>

        <section className="o-section">
          <div className="u-wrapper">
            <ul className="c-social">
              <li><a href="https://www.linkedin.com/company/6461947/admin/" className="u-color-linkedin">LinkedIn</a></li>
              <li><a href="https://www.instagram.com/warehouse_six/" className="u-color-instagram">Instagram</a></li>
              <li><a href="https://twitter.com/warehouse_six" className="u-color-twitter">Twitter</a></li>
            </ul>
          </div>
        </section>
      
    </Layout>
  
    )

}

export const query = graphql`
  {
    craft {
      projects: entries(section: "projects") {
        ... on Craft_projects_projects_Entry {
          id
          title
          slug
        
          thumbnail {
            id
            url
          }
        }
      }
    }
  }
`;

export default Contact
